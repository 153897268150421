<template>
  <div class="sign-up">
    <TheHeader
      :show-profile="false"
      class="fixed z-10"
    />
    <div class="flex">
      <div class="flex-1 pl-24 pt-12 w-1/2 form-section">
        <div class="content-section">
          <div class="text-4xl font-bold connect pt-16">
            {{ $t('createPageReal') }}
          </div>
          <div class="pt-6 text-xl">
            <span>
              {{ $t('haveAccount') }}
            </span>
            <span class="signIn">
              <router-link
                to="/"
                class="signIn"
              >
                {{ $t('signIn') }}
              </router-link>
            </span>
          </div>
          <div class=" parent mb-4 pt-10">
            <div class="w-full flex-1 w-1/2 px-1">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('enterpriseName') }}
              </label>
              <div class="flex flex-wrap items-stretch w-5/6">
                <div class="flex">
                  <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-300 px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-home"
                      class="email-icon w-5"
                      :color="beforeFocusEntreprise"
                    />
                  </span>
                </div>
                <input
                  v-model="user.name"
                  type="text"
                  class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1 border border-l-0 h-12 border-gray-300 rounded rounded-l-none px-0"
                  placeholder="Ex: Company Inc."
                  @focus="changeEntrepriseColor"
                  @blur="resetEntrepriseColor"
                >
              </div>
            </div>
            <div class="w-full flex-1 w-1/2 px-1 mt-5">
              <label class="block text-gray-700 text-lg mb-2">
                {{ $t('domain') }}
              </label>
              <div class="w-5/6 rounded z-99 cursor-pointer">
                <rema-domain-select @chooseDomain="chooseDomain" />
              </div>
            </div>
            <div class="w-full flex-1 w-1/2 mt-5 px-1">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('description') }}
              </label>
              <div class="flex flex-wrap items-stretch w-5/6">
                <div class="flex">
                  <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-300 px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-description"
                      class="email-icon w-5"
                      original
                      :color="descColor"
                    />
                  </span>
                </div>
                <input
                  v-model="user.description"
                  type="text"
                  class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1 border border-l-0 h-12 border-gray-300 rounded rounded-l-none px-0 appearance-none relative"
                  :placeholder="$t('describeEntrepreprise')"
                  @focus="changeDescColor"
                  @blur="resetDescColor"
                >
              </div>
            </div>
            <div class="w-full flex-1 w-1/2 px-1 mt-5">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('country') }}
              </label>
              <div class="w-5/6 rounded border  z-99 cursor-pointer">
                <rema-country-select
                  @closeSelect="closeSelect"
                  @chooseCountry="chooseCountry"
                  @openSelect="openSelect"
                  @chooseCountryCode="chooseCountryCode"
                />
              </div>
            </div>
            <div class="w-full flex-1 w-1/2 px-1 mt-5 ">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('phone') }} ( {{ $t('telIndication') }} )
              </label>
              <div class="flex flex-wrap items-stretch w-5/6">
                <div class="flex">
                  <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-300 px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-phone"
                      class="email-icon w-5"
                      :color="telColor"
                      original
                    />
                  </span>
                </div>
                <input
                  v-model="user.tel"
                  type="text"
                  class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1 border border-l-0 h-12 border-gray-300 rounded rounded-l-none px-0 appearance-none relative"
                  placeholder="Ex: 299 62922082"
                  @focus="changeTelColor"
                  @blur="resetTelColor"
                  @keypress="isNumberOnly"
                >
              </div>
            </div>
            <div class="w-full flex-1 w-1/2 px-1 mt-5">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('email') }}
              </label>
              <div class="flex flex-wrap items-stretch w-5/6">
                <div class="flex">
                  <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-300 px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-email"
                      class="email-icon w-5"
                      :color="emailColor"
                      original
                    />
                  </span>
                </div>
                <input
                  v-model="user.email"
                  type="email"
                  class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1 border border-l-0 h-12 border-gray-300 rounded rounded-l-none px-0 appearance-none relative"
                  placeholder="Ex: contact@company.com"
                  @focus="changeEmailColor"
                  @blur="resetEmailColor"
                >
              </div>
            </div>
            <div class="w-full flex-1 w-1/2  px-1 mt-5">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('labelPassword') }}  ( {{ $t('passwordIndication') }} )
              </label>
              <div class="flex border flex-wrap items-stretch w-5/6">
                <div class="flex border-r-0 border-0">
                  <span class="flex items-center leading-normal    px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-password"
                      class="email-icon w-5"
                      :color="passColor"
                      original
                    />
                  </span>
                </div>
                <input
                  v-model="user.password"
                  :type="passType"
                  class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1   h-12  rounded rounded-l-none px-0 appearance-none relative"
                  placeholder="......"
                  @focus="changePassColor"
                  @blur="resetPassColor"
                >
                <div
                  v-if="wantToseePass"
                  class="flex"
                  @click="hidePass"
                >
                  <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-eye-open"
                      class="email-icon w-5"
                      :color="defaultColor"
                      original
                    />
                  </span>
                </div>
                <div
                  v-if="!wantToseePass"
                  class="flex"
                  @click="showPass"
                >
                  <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-eye-close"
                      class="email-icon w-5"
                      :color="defaultColor"
                      original
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="w-full flex-1 w-full px-1 mt-5">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('confirmPassword') }}
              </label>
              <div class="flex border rounded items-stretch w-5/6">
                <div class="flex">
                  <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-password"
                      class="email-icon w-5"
                      :color="confirmPassColor"
                      original
                    />
                  </span>
                </div>
                <input
                  v-model="user.passwordConf"
                  :type="showInput"
                  class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1   h-12  rounded rounded-l-none px-0 appearance-none relative"
                  placeholder="......"
                  @focus="changePassConfirmColor"
                  @blur="resetPassConfirmColor"
                >
                <div
                  v-if="wantToseeConfirmPass"
                  class="flex"
                  @click="hideConfPass"
                >
                  <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-eye-open"
                      class="email-icon w-5"
                      :color="defaultColor"
                      original
                    />
                  </span>
                </div>
                <div
                  v-if="!wantToseeConfirmPass"
                  class="flex"
                  @click="showConfPass"
                >
                  <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                    <svg-icon
                      name="ic-eye-close"
                      class="email-icon w-5"
                      :color="defaultColor"
                      original
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-0 mb-6">
            <div class="flex-1">
              <label class="block tracking-wide text-gray-700 text-lg mb-2">
                {{ $t('logo') }}
              </label>
              <h3
                v-if="imageData"
                class="w-8 h-8 rounded-full bg-white text-center relative delete-image cursor-pointer shadow"
                @click="imageData = ''"
              >
                <svg-icon
                  name="ic-delete"
                  class="w-6 h-6 p-1"
                  original
                />
              </h3>
              <div
                class="image-preview flex w-64 h-64  items-center rounded-lg justify-center bg-grey-lighter"
                :class="{'forDelete': imageData}"
              >
                <img
                  v-if="imageData"
                  ref="cropper"
                  class="preview w-full h-full rounded-lg z-1"
                  :src="imageData"
                  alt="useLogo"
                >
                <div
                  v-if="!imageData"
                  class="text-center rounded-full bg-white h-32 w-32"
                >
                  <label class="text-grey-darkest font-light w-3/5 inline-flex h-full items-center">
                    <!--<svg class="w-16 h-16 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>-->
                    <span class="text-base leading-normal text-center cursor-pointer">
                      <svg-icon
                        name="ic-camera"
                        class="icon-cam"
                        color="#BDBDBD"
                      />
                    </span>
                    <input
                      ref="myFiles"
                      type="file"
                      class="hidden"
                      accept="image/*"
                      @change="previewImage"
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex ">
            <label class="flex items-center">
              <input
                type="checkbox"
                checked="checked"
                class="form-checkbox"
              >
              <span class="ml-2">{{ $t('accept') }}<span
                class="condition"
                @click="openUrl"
              >{{ $t('acceptCondition') }}</span></span>
            </label>
          </div>
          <button
            class="buttonSignUp py-3 mb-12 -mx-2 mt-8 w-1/2 h-12 rounded text-white text-xl"
            @click="singUp"
          >
            <span v-if="!loading"> {{ $t('createPage') }}</span>
            <pulse-loader
              v-if="loading"
              color="#ffffff"
            />
          </button>
        </div>
      </div>
      <div class="flex-1 h-full w-1/2 second-block">
        <div class="bloc-picture fixed">
          <img
            :src="vector"
          >
          <img
            :src="group"
            class="groupe object-center"
          >
          <img
            :src="ellipse"
            class="ellipse"
          >
        </div>
      </div>
    </div>
    <!--   <a class="btn" @click="toggleShow">set avatar</a>
    <my-upload field="img"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail"
               v-model="show"
               :width="600"
               :height="600"
               lang-type="fr"
               img-format="*"></my-upload>
    <img :src="imgDataUrl">-->
    <rema-cropper
      v-if="wantToCrop"
      :before-image="selectedImageForCrop"
      @imageCropped="CropSucces"
      @cancelImageCropped="cancelImageCropped"
    />
    <success-sign-up
      v-if="successCreate"
      @clickForConnect="clickForConnect"
    />
  </div>
</template>

<script>
import firebase from 'firebase'
import firebaseDatabase from '../rema-function/firebaseDatabase'
import TheHeader from '../components/the-header'
import group from '../assets/images/group.svg'
import group1 from '../assets/images/ellipse.svg'
import group2 from '../assets/images/vector.svg'
import RemaCountrySelect from '../components/helper/rema-components/rema-country-select'
import RemaDomainSelect from '../components/helper/rema-components/rema-domain-select'
import RemaCropper from '../components/helper/rema-components/rema-cropper'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import SuccessSignUp from '../components/helper/rema-components/success-sign-up'
import { remaDefaultData } from '../rema-function/remaDefaultData'
export default {
  name: 'SignUp',
  components: {
    SuccessSignUp,
    PulseLoader,
    RemaCropper,
    RemaDomainSelect,
    RemaCountrySelect,
    TheHeader
  },
  data () {
    return {
      group: group,
      ellipse: group1,
      vector: group2,
      imageData: '',
      showFile: false,
      defaultColor: '#BEBEBE',
      beforeFocusEntreprise: '#BEBEBE',
      emailColor: '#BEBEBE',
      telColor: '#BEBEBE',
      descColor: '#BEBEBE',
      passColor: '#BEBEBE',
      confirmPassColor: '#BEBEBE',
      user: {
        name: '',
        domaine: '',
        description: '',
        localisation: '',
        tel: '',
        email: '',
        password: '',
        passwordConf: '',
        postAvatar: '',
        countryCode: ''
      },
      uploadImage: [],
      wantSelectCountry: false,
      wantToCrop: false,
      selectedImageForCrop: '',
      topMar: '3rem',
      loading: false,
      successCreate: false,
      wantToseeConfirmPass: false,
      showInput: 'password',
      wantToseePass: false,
      passType: 'password'
    }
  },
  methods: {
    previewImage: function ($event) {
      let files = $event.target.files
      if (!files.length) {
        this.showFile = false
        return
      }
      this.uploadImage = files[0]
      this.createImage(files[0])
      console.log(this.files = this.$refs.myFiles.files)
    },
    createImage (file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.selectedImageForCrop = e.target.result
      }
      reader.readAsDataURL(file)
      vm.wantToCrop = true
    },
    chooseCountry (answer) {
      this.user.localisation = answer
      // alert(this.user.country)
    },
    chooseCountryCode (answer) {
      this.user.countryCode = answer
    },
    chooseDomain (answer) {
      this.user.domaine = answer
    },
    changeEntrepriseColor () {
      this.beforeFocusEntreprise = '#000000'
    },
    CropSucces (answer) {
      this.imageData = answer
      this.user.postAvatar = answer
      this.wantToCrop = false
    },
    cancelImageCropped (answer) {
      this.wantToCrop = answer
      this.selectedImageForCrop = ''
    },
    resetEntrepriseColor () {
      this.beforeFocusEntreprise = this.defaultColor
    },
    changeEmailColor () {
      this.emailColor = '#000'
    },
    resetEmailColor () {
      this.emailColor = this.defaultColor
    },
    changeTelColor () {
      this.telColor = '#000'
    },
    resetTelColor () {
      this.telColor = this.defaultColor
    },
    changeDescColor () {
      this.descColor = '#000'
    },
    resetDescColor () {
      this.descColor = this.defaultColor
    },
    changePassColor () {
      this.passColor = '#000'
    },
    resetPassColor () {
      this.passColor = this.defaultColor
    },
    changePassConfirmColor () {
      this.confirmPassColor = '#000'
    },
    resetPassConfirmColor () {
      this.confirmPassColor = this.defaultColor
    },
    closeSelect () {
    },
    openSelect () {

    },
    singUp () {
      if (this.controlInput()) {
        this.loading = true
        let vm = this
        firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
          .then((res) => {
            if (firebaseDatabase.saveUser(vm.user, res.user.uid)) {
              vm.loading = false
              vm.successCreate = true
            }
          })
          .catch((err) => {
            this.loading = false
            alert(err.toString())
          })
      }
    },
    controlInput () {
      if (this.user.password !== '' && this.user.passwordConf !== '' && this.user.email !== '' && this.user.domaine !== '' && this.user.localisation !== '' &&
          this.user.description !== '' && this.user.tel !== '' && this.user.name !== '' && this.user.postAvatar !== '') {
        if (this.user.password === this.user.passwordConf) {
          return true
        } else {
          alert('Les mots de passe ne sont pas identique')
          return false
        }
      } else {
        alert('Tous les champs sont obligatoires')
        return false
      }
    },
    clickForConnect () {
      this.successCreate = false
      firebase.auth().signOut()
        .then(() => {
          this.$router.replace('/')
        })
        .catch((err) => {
          alert(err.toString())
        })
    },
    showConfPass () {
      this.wantToseeConfirmPass = true
      this.showInput = 'text'
    },
    hideConfPass () {
      this.wantToseeConfirmPass = false
      this.showInput = 'password'
    },
    showPass () {
      this.wantToseePass = true
      this.passType = 'text'
    },
    hidePass () {
      this.wantToseePass = false
      this.passType = 'password'
    },
    isNumberOnly ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    openUrl () {
      window.open(remaDefaultData.cguLink)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/sass/variables";
  .sign-up {
    overflow: hidden;
    /* height: 100%; */
    .connect, .signIn, .condition {
      color: $Green-Rema;
    }
    .parent {
      //height: 8rem;
      width: 80%;
    }
    .parents {
     // height: 5.5rem;
      width: 80%;

    }
    .buttonSignUp, .bloc-picture {
      background-color: $Green-Rema;
    }
    .groupe {
      position: relative;
      top: -13rem;
      left: 5rem;
      z-index: 1;
    }
    .ellipse {
      position: relative;
      top: -45rem;
      left: 25rem;
    }
    .countryList {
      margin-left: 2.5rem;
      width: 16.5rem;
      margin-top: 3rem;
      padding: .5rem;
      background-color: white;
      height: 10rem;
      overflow-y: scroll;
    }
    /* .bloc-picture {
      position: fixed;
    } */
    .icon-cam {
      width: 4.5rem;
      height: 4.5rem;
      padding-left: .3rem;
      margin-top: .2rem;
    }
    .delete-image {
      margin-left: 14.5rem;
      padding-top: .2rem;
    }
    .forDelete {
      margin-top: -2rem !important;
    }
    .content-section {
    }
  }
  @media (max-width: 600px) {
   .second-block {
     display: none;
   }
    .form-section {
      padding: 3.3rem;
    }
    .connect {
      padding-top: 3rem;
    }
    .parents {
      flex-direction: column;
    }
    .parent {
      flex-direction: column;
    }
  }
</style>
