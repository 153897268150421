<template>
  <div class="rema-domain-select">
    <div
      class="flex border w-full rounded"
      @click="showList"
    >
      <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
        <svg-icon
          name="ic-domaine"
          class="email-icon w-4"
          :color="domainColor"
          original
        />
      </span>
      <input
        v-model="domain"
        type="text"
        name="diseableCountry"
        :placeholder="$t('selectDomain')"
        autocomplete="off"
        class="input disable flex-shrink flex-grow flex-auto bg-white leading-normal w-px flex-1  h-12   px-0 appearance-none "
      >
    </div>
    <div
      v-if="wantSelectCountry"
      class="countryList relative z-10 shadow-md rounded overflow-y"
    >
      <h3
        v-for="(item, key) in countries"
        :key="item+key"
        class="p-2 text-lg cursor-pointer font-light"
        @click="selectCountry(item)"
      >
        {{ item }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RemaDomainSelect',
  data () {
    return {
      countries: [
        'Laboratoire pharmaceutique', 'Agence de représentation pharmaceutique', 'Agence de communication en santé', 'Ministère de santé', 'Santé Numérique', 'Organisation sanitaire', 'Fondation',
        'Association & ONG', 'Matériels biomédicaux', 'Autres'
      ],
      domain: '',
      domainColor: '#BEBEBE',
      wantSelectCountry: false
    }
  },
  methods: {
    selectCountry (item) {
      this.domain = item
      this.wantSelectCountry = false
      this.domainColor = '#BEBEBE'
      this.$emit('chooseDomain', this.domain)
    },
    showList () {
      if (this.wantSelectCountry) {
        this.domainColor = '#BEBEBE'
        this.wantSelectCountry = false
      } else {
        this.domainColor = '#000'
        this.wantSelectCountry = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .countryList {
    background-color: white;
    height: 10rem;
    overflow-y: scroll;
  }
  input[name='diseableCountry'] {
    pointer-events: none;
  }
</style>
