<template>
  <div class="rema-country-select">
    <div
      class="flex w-full rounded"
      @click="showCountryList"
    >
      <span class="flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
        <svg-icon
          name="ic-location"
          class="email-icon w-4"
          original
          :color="countryColor"
        />
      </span>
      <input
        v-model="country"
        type="text"
        name="diseableCountry"
        :placeholder="$t('selectCountry')"
        class="input disable flex-shrink flex-grow border-b flex-auto bg-white leading-normal w-px flex-1  h-12   px-0 appearance-none "
      >
    </div>
    <div
      v-if="wantSelectCountry"
      class="countryList relative border z-10 shadow-md rounded overflow-y"
    >
      <h3
        v-for="(item, key) in countries"
        :key="item+key"
        class="p-2 text-lg cursor-pointer font-light"
        @click="selectCountry(item)"
      >
        {{ item.text }}
      </h3>
    </div>
  </div>
</template>

<script>
import allCountries from '../../../assets/allcountries.json'

export default {
  name: 'RemaCountrySelect',
  data () {
    return {
      countries: [],
      country: '',
      wantSelectCountry: false,
      countryColor: '#BEBEBE',
      allCountrie: allCountries
    }
  },
  async created () {
    let vm = this
    // this.allCountrie.forEach((val) => {
    //   vm.countries.push({
    //     name: val.name,
    //     alpha2Code: val.alpha2Code,
    //     alpha3Code: val.alpha3Code,
    //     callingCodes: val.callingCodes[0],
    //     text: val.translations.fr
    //   })
    // })
    fetch('https://restcountries.com/v3.1/all')
      // alert('hi la mifa')
      .then(response => response.json())
      .then(data => {
        // alert('hhi')
        console.log('see-data' + data.toString())
        // data.forEach(function (val) {
        //   if (/^2/.test(val.callingCodes[0])) {
        //     vm.countries.push({
        //       name: val.name,
        //       alpha2Code: val.alpha2Code,
        //       alpha3Code: val.alpha3Code,
        //       callingCodes: val.callingCodes[0],
        //       text: val.translations.fr
        //     })
        //   }
        // })
        data.forEach(function (val) {
          console.log(val)

          if (typeof val.idd.suffixes !== 'undefined') {
            vm.countries.push({
              name: val.name.common,
              callingCodes: val.idd.root + val.idd.suffixes[0],
              text: val.translations.fra.common,
              map: val.flag
            })
          }
        })
        vm.countries.sort(function (a, b) {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
        })
        console.log(vm.countries)
      })
      .catch(err => {
        console.error('An error ocurred', err)
        alert(err.toString())
      })
  },
  methods: {
    selectCountry (item) {
      this.country = item.text
      this.wantSelectCountry = false
      this.countryColor = '#BEBEBE'
      this.$emit('chooseCountry', item.name)
      this.$emit('chooseCountryCode', item.callingCodes)
    },
    showCountryList () {
      if (this.wantSelectCountry) {
        this.wantSelectCountry = false
        this.countryColor = '#BEBEBE'
        this.$emit('closeSelect', false)
      } else {
        this.wantSelectCountry = true
        this.countryColor = '#000'
        this.$emit('openSelect', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .countryList {
    background-color: white;
    height: 10rem;
    overflow-y: scroll;
  }
  input[name='diseableCountry'] {
    pointer-events: none;
  }
</style>
