<template>
  <div class="succes-sign-up">
    <popup-base>
      <div class="bg-white rounded w-1/2  p-4 success-bloc">
        <h3 class=" text-center svg-section">
          <svg-icon
            name="ic-brawo"
            original
            class="w-12 h-12">
          </svg-icon>
        </h3>
        <h3 class="text-medium text-center font-medium text-2xl uppercase text-light">Bravo !</h3>
        <h3 class="text-medium text-center font-light mt-2 text-xl ">{{ $t('successCreateAccount') }}</h3>
        <h3 class="text-medium text-center font-light mt-2 text-xl"> {{ $t('successCreateSubtitle') }} </h3>
        <div class="flex w-full justify-center">
          <h3 @click="clicForConnect" class=" w-1/2  text-center button cursor-pointer px-3 py-3  mt-4 connect-button text-white rounded">
            {{ $t('connect') }}
          </h3>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
export default {
  name: 'SuccessSignUp',
  components: { PopupBase },
  methods: {
    clicForConnect () {
      this.$emit('clickForConnect', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .connect-button {
    background-color: $Green-Rema;
  }
  .success-bloc {
    margin: auto;
    padding: auto;
  }
</style>
